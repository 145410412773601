import './App.css';

// Ideally I would like to use className="flex flex-row justify-content xs:flex-col" but flex-col does not take into effect when going to xs screen size. 
// So instead I am using multi-column


function App() {
  return (
    <div className="App">
      <img className="w-full" src="/banner.jpeg" />
      <div className="multi-column m-5">
        <div className="flex-1">
          <div className="w-96 xs:w-64 xs:m-auto">
            <img src="/what-is-club-lulu.jpeg" />
          </div>
          <div className="text-sm 2xl:text-3xl xl:text-2xl lg:text-xl md:text-lg sm:text-base pt-8">
            <p>
              Club Lulu is a collection of 3000 dachshund NFTs created by Founder, Franchesca Rousseas, a.k.a. Phran. The artwork for this project is inspired by the iconic weiner dog, Loukoumades, or Lulu for short.
            </p>
            <p>
              Club Lulu is a fun and playful project that is best known for its humorous and cute dachshunds that feature bright colors and imaginative outfits.
            </p>
          </div>
        </div>
        <div className="flex-1 m-auto" >
          <img className="w-full m-auto" src="/lulu.gif" />
        </div>
      </div>
      <div className="bg-lulu-pink p-5">
        <div className="w-96 m-auto xs:w-64">
          <img src="/roadmap.png" />
        </div>
        <div className="flex justify-center mx-auto mb-5 mt-5 w-96 xs:w-72">
          <img src="/cow-dog-lulus.jpeg" />
        </div>
        <div className="text-sm 2xl:text-3xl xl:text-2xl lg:text-xl md:text-xl sm:text-base">
          <p>
            It is my goal as an artist to make connections with my audience through creative means,
            and the same holds true for this project. Club Lulu is whimsical, light-hearted, and visually engaging.
            By injecting humor and fun into my (and soon to be our!) precious Lulus, I hope that you are reminded of
            your own personal memories and experiences. Whether the Lulus remind you of your own pet, a close friend,
            or a fresh, hot glizzy, it brings me great joy in knowing that you connect to Club Lulu on some level.
          </p>
          <p>
            We value our community, because YOU all are what makes Club Lulu so special. That being said,
            we want to give you the power to decide what Club Lulu’s future looks like. This means that there
            will be plenty of opportunities for you all to vote on what charities we donate to, special giveaways,
            the creation of new Club Lulu programs and more.
          </p>
          <p>
            - Phran, Max, Anish ❤️
          </p>
        </div>

      </div>

      <div className="mx-auto my-8 w-96 xs:w-64">
        <img src="/meet-the-team.jpeg" />
      </div>
      <div className="multi-column">
        <img className="w-1/3 xs:w-2/3 xs:m-auto" src="/phran_card.jpeg" />
        <img className="w-1/3 xs:w-2/3 xs:m-auto" src="/max_card.jpeg" />
        <img className="w-1/3 xs:w-2/3 xs:m-auto" src="/anish_card.jpeg" />
      </div>
      <div className="flex justify-center">
        <img className="w-3/5" src="/star.jpeg" />
      </div>
    </div >
  );
}

export default App;
