import { Navbar, Container, Nav } from "react-bootstrap";
import { FaDiscord, FaTwitter } from 'react-icons/fa';

function Header() {
    return (
        <Navbar sticky="top" bg="white" expand="lg">
            <Container>
                <Navbar.Brand href="#home">
                    <img alt="Club Lulu Logo" src="./icon.jpeg" width="200" height="auto" className="d-inline-block align-top" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar-nav" />
                <Navbar.Collapse id="navbar-nav" className="justify-content-end">
                    <Nav className="ms-auto">
                        <Nav.Link href="#top">Home</Nav.Link>
                        <Nav.Link href="#meet-the-team">About Us</Nav.Link>
                        <Nav.Link target="_blank" href="discord.gg/ZNfm733XZW">
                            <FaDiscord />
                        </Nav.Link>
                        <Nav.Link target="_blank" href="https://twitter.com/PhranStudio">
                            <FaTwitter />
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
export default Header;